@import 'variables.scss';

#root {
    height: 100%;
}

.main-layout {
    height: calc(100vh - 64px);
    padding: 0 1rem;
    position: relative;
    overflow: hidden;
}

.filters-drawer {
    position: absolute;

    z-index: 999;
    
    .ant-drawer-body { 
        border-left: 2px solid gainsboro;
        background-color: whitesmoke;
        padding: 16px 12px;
    }

    .ant-drawer-mask {
        background-color: transparent;
    }
}

/** ANTD OVERRIDE **/
.ant-spin-container {
    transition-duration: 1s;
}

.ant-select-selection-search-input input::placeholder {
    font-style: italic;
}

.ant-tabs-top > .ant-tabs-nav::before {
    border-color: lightgray;
}

.configuration-drawer {

    input {
        text-align: right;
    }

    .ant-drawer-title .anticon {
        margin-right: 0.5rem;
    }

    .ant-drawer-body {
        padding: 0.5rem 1rem;
    }
}

.ant-tabs-tab {

    margin: 0 8px 0 0;
    padding: 12px;

    .tab-title {

        position: relative;
    
        span {
            position: absolute;
            right: -28px;
            top: -12px;
            font-size: 12px;
            opacity: 0;
            transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
        }
    }
    
    &:hover {
        .tab-title span {
            opacity: 1;
            transform: translateY(6px);
        }
    }
}

.ant-form-large .ant-form-item-label > label {
    height: auto;
}

.ant-form-item-has-error .ant-radio-group {
    border: 1px solid red;

    .ant-radio-button-wrapper {
        border-top-width: 0;
        border-bottom-width: 0;
    }

    .ant-radio-button-wrapper:first-child {
        border-left-width: 0;
    }

    .ant-radio-button-wrapper:last-child {
        border-right-width: 0;
    }
}

.radio-group--expanded.ant-radio-group {
    width: 100%;
    display: flex;
    
    .ant-radio-button-wrapper {
        flex: 1 1 auto;
    }
}

.select-tag--centered {

    .ant-select-selection-item-content {
        display: flex;
        align-items: center;
    }

    .ant-select-selection-item-remove {
        display: flex;
        align-items: center;
    }
}

/** NEW CHANGES **/
.ant-layout {
    background: white;
}

.ant-table {
    color: $main-color !important;

    .ant-table-thead > tr > th {
        font-weight: bold;
        border-bottom: 0;
        background-color: whitesmoke;
    }

    .ant-table-tbody > tr  {
        
        &:nth-child(2n) {
            background-color: #FAFAFA;
        }

        & > td {
            border-bottom: 0;
        }
    }

    .ant-table-tbody > tr.ant-table-measure-row ~ tr  {

        &:nth-child(2n) {
            background-color: white;
        }

        &:nth-child(2n + 1) {
            background-color: #FAFAFA;
        }
    }
}

.ant-btn {

    font-size: 0.85rem;

    &:not(.ant-btn-circle) {
        border-radius: 4px;
    }

    &:not(.ant-btn-dangerous):not(.ant-btn-link) {
        border: 1px solid #1890ff;
    }
}

.ant-upload-list-item {
    .ant-btn:not(.ant-btn-dangerous):not(.ant-btn-link) {
        border: none !important;
    }
}

/** Overriden default input styles **/
.ant-input-affix-wrapper, .ant-input, .ant-picker, .ant-select .ant-select-selector {
    border-radius: 4px !important;
    background-color: #F8F8F8 !important;

    box-shadow: none !important;
}

.filters-drawer {
    .ant-input-affix-wrapper, .ant-input, .ant-picker, .ant-select .ant-select-selector {
        background-color: white !important;
    }
}

.ant-input-affix-wrapper .ant-input {
    border-radius: 0 !important;
}

.ant-layout-sider {

    .ant-input, .ant-picker, .ant-select .ant-select-selector,
    .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
        background-color: white !important;
    }
}


input:not(:hover):not(:focus):not([disabled]),
.ant-select:not(.ant-select-focused):not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector:not(:hover) {
    //border-color: transparent !important;
}

.ant-select-show-search {
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
        left: 0;
    }

    input {
        border-radius: 0 !important;
    }
}

.ant-input-group {

    .ant-input-group-addon {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border-radius:  0 !important;
        border: 1px solid #d9d9d9;
        border-left: 1px solid transparent !important;
        border-right: 1px solid transparent !important;
    }
    .ant-input {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}

/** CUSTOM **/
.app-loading-mask {
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(256, 256, 256, 0.5);
    color: $icon-color;
    align-items: center;
    justify-content: center;
    
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
}

.icon-color {
    color: $icon-color
}

.tabpanel-height-100 {
    height: 100%;

    .ant-tabs-content {
        height: 100%;
    }
}

.input-right-align {
    text-align: right;

    input {
        text-align: right;
    }
}