.dialog-confirmation {
  margin-top: 1rem;

  .dialog-confirmation-logo {
    display: flex;
    justify-content: center;
  }

  .dialog-confirmation-text {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

  }

  .dialog-confirmation-button {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 1rem;
  }
}