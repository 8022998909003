.div-centered {
  justify-content: center;
  text-align: center;
}

.ant-upload-select-text{
  width: 100%;
  button  {
    width: 100%;
  }  
}

.div-history-header {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 1rem;
}

.div-history-content {
  margin-top: 20px;
  margin-left: 30px;
}  
