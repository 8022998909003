@import '~antd/dist/antd.css';
@import 'css/flags.css';
@import 'variables.scss';
@import 'common.scss';

.logo {
    font-weight: bold;
    font-size: 1.75rem;
    text-align: center;
    margin: 0.75rem;
    background-color: gray;
    margin-bottom: 1rem;
    line-height: normal;
    border-radius: 4px;
}

/** ANTD OVERRIDE **/

.ant-form-item-has-error .ant-slider {
    border: 1px solid red;

    .ant-slider-handle.ant-tooltip-open {
        border-color: red;
    }
}

/** CUSTOM **/
.table-button-bar {
    margin-bottom: 1rem;
}

/** LOGIN **/
.login-container {
    background: url('./images/login-bg.jpg') no-repeat top center;
    height: 100%;
    width: 100%;
    position: fixed;
    background-size: cover;

    .login-container--mask {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: white;
        opacity: 0.25;
    }

    .form {
        position: relative;
        margin: 0 auto;
        margin-top: 8rem;
        width: 24rem;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: 0px 2px 128px black;
        background-color: white;
        padding: 1rem 2rem;

        &.otp {
            text-align: center;
        }
    }

    .header {
        text-align: center;
        color: white;
        margin: 0.5rem 0 1.5rem;

        img {
            width: 256px;
        }
    }

    .warning_Message {
        text-align: left;
        width: 100%;
    }

    .error_Message {
        text-align: left;
        width: 100%;
    }

    .login_otp_text {

        input {
            margin-top: 1rem;
            margin-bottom: 1.5rem;
            width: 2.5rem;
            height: 2.5rem;
            font-size: 3rem;
            text-align: center;
            padding-top: 0rem;
            padding-left: 10px;
        }

        &.ant-form-item-with-help {
            margin-bottom: 0;

            .ant-form-item-explain {
                display: none;
            }
        }
    }

    .regenerate-password {
        text-align: center;
        font-weight: bold;
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
    }
}

.header-app {
    
    height: 64px; 
    padding-left: 2rem;
    padding-right: 2rem;

    background-color: white;

    box-shadow: 0 2px 8px darkgray;
    z-index: 1000;

    .header--logo {
    
        .header--logo--inner {
            width: 256px;
            img {
                width: 100%;
            }
        }
    }

    .header-options--container {
    
        .header-options--option + .header-options--option {
            margin-left: 2rem;
        }
    
        .ant-badge-count {
            box-shadow: none;
        }
    
        [class*="fa"] {
            cursor: pointer;
            font-size: 2rem;
        }
    }
}

.content-app {

    .ant-layout-sider {

        &, .ant-menu {
            background-color: whitesmoke;
        }

        .ant-menu {
            margin-top: 4px;
            border-right-width: 0;
        }

        .ant-menu-item {
            height: 44px;
            line-height: 44px;
            padding-top: 4px;
            svg {
                font-size: 24px;
            }
        }
    }
}

.loading-img {
    background: url('./images/loading.gif') no-repeat center center;
    background-size: contain;
    display: inline-block;
}

.loading-img--inline {
    min-width: 2rem;
    display: inline-block;

    img {
        max-height: 1.75rem; 
        margin-right: 0.5rem;
    }
}

.table-container {
    position: relative;
}

.table__custom-expandable-icon {

    .ant-table-expand-icon-col {
        width: 42px;
    }
    
    .ant-table-row-expand-icon-cell {
        padding-left: 0;
        padding-right: 0;

        .fa-chevron-down {
            color: $icon-color;
        }
    }
}

.paragraphs__container {
    padding: 1rem;
    background-color: #F4F5F7;
    border: 1px solid #C1C7D0;

    border-radius: 4px;

    p {
        margin-bottom: 0;
    }
}

.table-expandable-detail__arrow {
    color: #1890ff;
    transform: scale(-1, 1);
    font-size: 2rem;
    margin-left: 0.75rem;
    position: relative;
    top: -8px;
}

.table-expandable-detail {
    padding: 0.25rem 0.5rem;
    border: 2px solid lightgray;
    border-radius: 4px;

    svg {
        margin: 0 0.25rem;
        color: gray;
    }
}

.enlace_token_container {
    background-color: whitesmoke;
    height: 100%;
    display: flex;
    justify-content: center;

    .enlace_token_card_container {
        justify-content: center;

        .enlace_token_card {
            margin-top: 2rem;
            background-color: white;
            width: 400px;
            padding: 2rem;
            border-radius: 4px;
            box-shadow: 0px 2px 8px gainsboro;

            .enlace_token_logo {
                display: flex;
                justify-content: center;

                img {
                    max-width: 200px;
                }
            }
            
            .enlace_token_title {
                margin-top: 1.5rem;
                font-weight: bold;
                text-align: center;
                font-size: 1rem;
            }
        
            .enlace_token_prestador {
                text-align: center;
                margin-top: 1rem;
                padding-bottom: 0;

                &.welcome {
                    text-align: left;
                }
            }
        
            .acept-consent {
                display: flex;
                justify-content: center;
                margin-top: 1rem;

                .acept-enlace_token_text {
                    margin-left: 0.5rem;
                }
            }
        
            .enlace_token_result {
                margin-top: 1rem;
                display: flex;
                
                .enlace_token_result-logo {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 0.5rem;

                    .not-valid-logo {
                        border-radius: 50%;
                        background-color: #f5222d;
                        padding: 6px;
                        width: 33px;
                        height: 33px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            font-size: 1.25rem;
                            color: white;
                        }
                    }
                }
            
                .enlace_token_result-text {
                    margin-left: 0.5rem;
                }
            }

            .enlace_token_buttons {
                margin-top: 1rem;
                display: flex;
                justify-content: center;
            }

            .alta-buttons {
                margin-top: 1rem;
                display: flex;
                justify-content: center;
            }

            .ant-form + .alta-buttons {
                margin-top: 0rem;
            }

            .form_step2 {
                margin-top: 1rem;
                justify-content: center;
            }
        }
    }
}

.credential_condition {
    margin-top: 1rem;
}

.form__password {
    margin-top: 1rem;
}